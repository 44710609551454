import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

// Use a single-spa parcel as a loading UI
// You may also use Angular, Vue, etc.
// const parcelConfig = singleSpaReact({...})

// or error parcel
// const myErrorParcel = singleSpaReact({...})

const data = {
  loaders: {
    splash_screen: `<nav class="placeholder">loading...</nav>`,
    // settings: settingsLoader // uncomment if using framework like angular
  },
  errors: {
    page_error: "<h1>Oops! Page isn't working right now</h1>",
    // alternatively:
    // navError: myErrorParcel
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
